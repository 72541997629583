enum DIFFICULTIES {
  Easy = 1,
  Medium = 2,
  Hard = 3,
}

export const LIST = [
  {
    name: "Easy",
    value: DIFFICULTIES.Easy,
    color: [245, 212, 188],
  },
  {
    name: "Medium",
    value: DIFFICULTIES.Medium,
    color: [250, 148, 76],
  },
  {
    name: "Hard",
    value: DIFFICULTIES.Hard,
    color: [244, 121, 35],
  },
];

export default DIFFICULTIES;
