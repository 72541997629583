import { useCallback, useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Theme,
  Select,
  SelectChangeEvent,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { ITest, IUserTest } from "../../types/test";
import TestQuiz from "../../components/TestQuiz";
import moment from "moment";

const Tests: React.FunctionComponent<{}> = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quizzesLoading, setQuizzesLoading] = useState<boolean>(false);
  const [test, setTest] = useState<ITest>();

  const [selectedTest, setSelectedTest] = useState<number>(0);
  const [userTests, setUserTests] = useState<IUserTest[]>([]);

  const loadTestMetadata = useCallback(() => {
    if (!selectedTest) {
      return;
    }

    axios
      .get<ITest>(`/Web/GetTest`, {
        params: {
          testId: selectedTest,
        },
      })
      .then((res) => {
        setTest((current) => ({
          ...current,
          ...res.data,
        }));
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [selectedTest]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IUserTest[]>("/Web/GetUserTests")
      .then((res) => {
        setUserTests(res.data);
        if (res.data.length > 0) {
          setSelectedTest(res.data[0].testId);
        }
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const loadQuizzes = useCallback(() => {
    if (!selectedTest) {
      return;
    }
    setQuizzesLoading(true);
    axios
      .get<ITest>(`/Web/GetTestQuizzes`, {
        params: {
          testId: selectedTest,
        },
      })
      .then((res) => {
        setTest(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setQuizzesLoading(false);
      });
  }, [selectedTest]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadQuizzes();
  }, [loadQuizzes]);

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Tests
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Select
              value={selectedTest.toString()}
              variant="filled"
              onChange={(event: SelectChangeEvent) => {
                setSelectedTest(parseInt(event.target.value));
              }}
              renderValue={(value) => {
                const test = userTests.find(
                  (test) => test.testId.toString() === value
                );

                if (!test) {
                  return "";
                }

                return `${test.contentName} in ${test.teamName}`;
              }}
            >
              {userTests.map((test) => (
                <MenuItem key={test.testId} value={test.testId}>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography>
                        {test.contentName} in {test.teamName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {moment.utc(test.startDate).format("L")} -{" "}
                        {moment.utc(test.endDate).format("L")}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
      </Grid>

      {isLoading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : test ? (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography>
              <b>Duration:</b>
              {`📅 ${moment.utc(test.startDate).format("L")} - ${moment
                .utc(test.endDate)
                .format("L")} (${
                moment.utc(test.endDate).isAfter(moment())
                  ? "started"
                  : "finished"
              })`}
            </Typography>
            <Typography>
              <b>Goal:</b> {test.correctAnswersCriteria}% correct answers
            </Typography>
            <Typography>
              <b>Correct answers:</b>{" "}
              {`${test.quizzesCorrectPercent}% (${test.quizzesCorrect}/${test.testQuizzes.length})`}
            </Typography>
          </Stack>
          <div>
            <Grid container spacing={4}>
              {_.zip(
                ..._.chunk(test?.testQuizzes, smDown ? 1 : mdDown ? 2 : 3)
              ).map((column, index) => (
                <Grid
                  key={index}
                  container
                  item
                  xs
                  spacing={4}
                  direction="column"
                  role="column"
                >
                  {column.map((quiz) =>
                    quiz ? (
                      <Grid key={quiz.testQuizId} item>
                        <TestQuiz
                          orderNumber={
                            test?.testQuizzes.findIndex(
                              (q) => q.testQuizId === quiz.testQuizId
                            ) + 1
                          }
                          quiz={quiz}
                          callback={() => {
                            loadTestMetadata();
                          }}
                        />
                      </Grid>
                    ) : null
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </Stack>
      ) : quizzesLoading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Typography variant="h5">
          You haven't been assigned any tests yet. Tests are managed by your
          workspace admins.
        </Typography>
      )}
    </Stack>
  );
};

export default Tests;
