import { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Popover,
  Link,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import moment from "moment";
import {
  IContent,
  IContentFilters,
  IContentResponse,
} from "../../types/content";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import { track } from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import { CoursesHelper } from "../../constants/helpers";
import Helper from "../../components/Helper";
import Eye from "../../components/Icons/Eye";
import Edit from "../../components/Icons/Edit";
import Plus from "../../components/Icons/Plus";
import GridToolbar from "../../components/Grid/Toolbar";

interface IQueryOptions {
  languageId?: number;
  modifiedById?: number;
}

const MyQuizes = () => {
  const dispatch = useAppDispatch();

  const tour = useAppSelector((state) => state.tour);
  const languages = useAppSelector((state) => state.classifiers.languages);
  const [filterData, setFilterData] = useState<IContentFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({
    languageId: undefined,
    modifiedById: undefined,
  });

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<IContentResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<IContentResponse>("/Content/GetContent", {
        params: {
          page: paginationModel.page + 1,
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page, queryOptions]);

  const duplicateContent = useCallback(
    (id: number) => {
      track("DuplicateContentClicked");
      setLoading(true);
      axios
        .post("/Content/DuplicateContent", null, {
          params: {
            questionCategoryId: id,
          },
        })
        .then(() => {
          toast("Course duplicated successfully", {
            type: "success",
          });
          loadData();
        });
    },
    [loadData]
  );

  useEffect(() => {
    axios
      .get<IContentFilters>("/Content/GetUserFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    loadData();

    track("OpenedContentView");
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<IContent>) => {
        return (
          <Link component={RouterLink} to={`/courses/${params.id}`}>
            <Typography>{params.value}</Typography>
          </Link>
        );
      },
    },
    {
      field: "languageId",
      headerName: "Language",
      valueFormatter: (params: GridValueFormatterParams) => {
        const lang = languages.find(
          (language) => language.languageId === params.value
        );

        return lang ? lang.languageName : "";
      },
      sortable: false,
      minWidth: 130,
    },
    {
      field: "lessons",
      headerName: "Active lessons",
      valueGetter: (params: GridValueGetterParams<IContent>) => {
        return `${params.row.enabledLessons}/${params.row.totalLessons}`;
      },
      minWidth: 185,
      sortable: false,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      sortable: false,
      minWidth: 155,
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        moment(params.value as Date).format("L"),
      minWidth: 160,
    },
    {
      field: "Actions",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <RouterLink to={`/courses/${params.id}`}>
              <IconButton>{params.row.canEdit ? <Edit /> : <Eye />}</IconButton>
            </RouterLink>

            <IconButton
              disabled={!params.row.canDuplicate}
              onClick={() => duplicateContent(params.id as number)}
            >
              <ContentCopyIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Courses
            </Typography>
            <Helper title={<CoursesHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            className="create-course-button"
            variant="contained"
            startIcon={<Plus color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.CreateContent,
                })
              );
            }}
          >
            Create course
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        className="courses-grid"
        ref={gridRef}
        getRowId={(row) => row.questionCategoryTranslationsId}
        rows={data ? data.content : []}
        rowCount={data?.numberOfEntries}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        slots={{
          toolbar: () => (
            <GridToolbar
              filterCount={
                Object.values(queryOptions).filter((item) => item).length
              }
              toggleFilter={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            />
          ),
        }}
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={languages ? languages : []}
            value={
              languages
                ? languages.find(
                    (item) => item.languageId === queryOptions.languageId
                  )
                : null
            }
            getOptionLabel={(option) => option.languageName}
            renderInput={(params) => <TextField {...params} label="Language" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                languageId: value ? value.languageId : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.users : []}
            value={
              filterData
                ? filterData.users.find(
                    (item) => item.id === queryOptions.modifiedById
                  )
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => (
              <TextField {...params} label="Modified By" />
            )}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                modifiedById: value ? value.id : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default MyQuizes;
