import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";

import QuestionCircle from "./Icons/QuestionCircle";
import { IQuiz } from "../types/quiz";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import FsLightbox from "fslightbox-react";

import {
  ILessonFeedbackType,
  LESSON_FEEDBACK_TYPE_LIST,
  LessonFeedbackType,
} from "../constants/lessonFeedbackTypes";
import _ from "lodash";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";

import globalIcon from "../assets/img/global.png";
import { LoadingButton } from "@mui/lab";
import { track } from "@amplitude/analytics-browser";

interface IQuizProps {
  quiz: IQuiz;
  callback?: () => void;
}

const Quiz: React.FC<IQuizProps> = ({ quiz, callback }) => {
  const [selectedValue, setSelectedValue] = useState(quiz.answerGivenId);
  const [localCopy, setLocalCopy] = useState<IQuiz>(quiz);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [toggler, setToggler] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onFeedback = useCallback(
    async (badgeTypeId: LessonFeedbackType) => {
      setIsFeedbackLoading(true);

      track("SubmitLessonFeedbackWeb");

      axios
        .post("/Web/SaveQuizFeedback", {
          quizId: quiz.quizId,
          badgeTypeId: badgeTypeId,
        })
        .then((res) => {
          setLocalCopy(res.data);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setIsFeedbackLoading(false);
        });
    },
    [quiz.quizId]
  );

  const onSubmit = useCallback(async () => {
    if (!selectedValue) {
      return;
    }

    track("CompleteLessonWeb");

    setIsLoading(true);
    axios
      .post("/Web/SaveQuizAnswer", {
        quizId: quiz.quizId,
        answerId: selectedValue,
      })
      .then((res) => {
        setLocalCopy(res.data);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.log("err:", err);
        setIsLoading(false);
      });
  }, [quiz.quizId, selectedValue, callback]);

  useEffect(() => {
    setLocalCopy(quiz);
  }, [quiz]);

  return (
    <Paper
      style={
        quiz.answerGivenId
          ? {
              backgroundColor: "#FFFCFA",
              border: "1px solid #8D8A88",
              borderRadius: 25,
            }
          : {
              border: "1px solid #8D8A88",
              borderRadius: 25,
            }
      }
      sx={{
        padding: 2,
      }}
      elevation={0}
    >
      <Grid container direction="column">
        <Grid item>
          <StyledTypography variant="caption" answered={Boolean(localCopy.answerGivenId)}>
            {moment.utc(localCopy.createdOn).format("L")}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography variant="h5" answered={Boolean(localCopy.answerGivenId)}>
            <b>{localCopy.channelName}</b>
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography answered={Boolean(localCopy.answerGivenId)}>
            <b>{localCopy.questionCategoryName}</b>{" "}
            {localCopy.questionCategoryIsGlobal && (
              <img src={globalIcon} alt="Global content" width={13} height={13} />
            )}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography answered={Boolean(localCopy.answerGivenId)}>
            <b>Question:</b>
            {/* @ts-ignore */}
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {localCopy.question}
            </ReactMarkdown>
          </StyledTypography>
        </Grid>
        {localCopy.questionJPG && (
          <Grid item container justifyContent="center">
            <Grid item>
              <Card
                style={{ maxWidth: 345, borderRadius: 0, border: "none" }}
                component="div"
                elevation={0}
              >
                <CardActionArea
                  onClick={() => {
                    if (localCopy.videoUrl) {
                      window.open(localCopy.videoUrl, "_blank");
                    } else {
                      setToggler((e) => !e);
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    style={{ maxWidth: 345, maxHeight: 160, objectFit: "contain" }}
                    image={localCopy.questionJPG}
                    alt="uploaded image"
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <FsLightbox
              toggler={toggler}
              sources={[localCopy.questionJPG]}
              showThumbsOnMount={true}
              type="image"
            />
          </Grid>
        )}
        <Grid item>
          <FormControl>
            <RadioGroup
              defaultValue={quiz.answerGivenId}
              value={selectedValue || 0}
              onChange={(event) => {
                setSelectedValue(parseInt(event.target.value));
              }}
            >
              {localCopy.answers.map((answer) => (
                <FormControlLabel
                  key={answer.id}
                  value={answer.id}
                  disabled={Boolean(localCopy.answerGivenId)}
                  control={<Radio color="primary" />}
                  style={
                    localCopy.answerGivenId &&
                    !answer.isCorrect &&
                    localCopy.answerGivenId !== answer.id
                      ? { opacity: 0.6 }
                      : undefined
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={
                        localCopy.answerGivenId === answer.id && !answer.isCorrect
                          ? { color: "red" }
                          : answer.isCorrect
                          ? { color: "green" }
                          : undefined
                      }
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {answer.text}
                      {localCopy.answerGivenId && answer.isCorrect && localCopy.description ? (
                        <Tooltip
                          open={isTooltipOpen}
                          arrow
                          style={{
                            cursor: "help",
                          }}
                          title={
                            <Typography
                              style={{
                                color: "#fff",
                                fontSize: 14,
                              }}
                            >
                              {/* @ts-ignore */}
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                {localCopy.description}
                              </ReactMarkdown>
                            </Typography>
                          }
                          onClose={() => setIsTooltipOpen(false)}
                          onOpen={() => setIsTooltipOpen(true)}
                        >
                          <IconButton size="small" onClick={() => setIsTooltipOpen(true)}>
                            <QuestionCircle
                              style={{
                                position: "relative",
                                top: 1,
                              }}
                              width={21}
                              height={21}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {!localCopy.answerGivenId ? (
          <Grid item>
            <LoadingButton
              variant="contained"
              disabled={!selectedValue}
              loading={isLoading}
              onClick={onSubmit}
            >
              Submit
            </LoadingButton>
          </Grid>
        ) : localCopy.feedback ? (
          <Grid item>
            <StyledTypography variant="caption" answered={Boolean(localCopy.answerGivenId)}>
              Good job, you rated the lesson as{" "}
              {LESSON_FEEDBACK_TYPE_LIST[localCopy.feedback]?.emoji}!
            </StyledTypography>
          </Grid>
        ) : (
          [
            <Grid key="text" item style={{ paddingTop: 12 }}>
              <StyledTypography variant="caption" answered={Boolean(localCopy.answerGivenId)}>
                How would you rate this lesson?
              </StyledTypography>
            </Grid>,
            <Grid key="actions" container item spacing={2}>
              {_.map(
                LESSON_FEEDBACK_TYPE_LIST,
                (feedbackType: ILessonFeedbackType, key: LessonFeedbackType) => (
                  <Grid key={key} item>
                    <Button
                      variant="contained"
                      onClick={() => onFeedback(key)}
                      disabled={isFeedbackLoading}
                    >
                      {feedbackType.emoji}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>,
          ]
        )}
      </Grid>
    </Paper>
  );
};

const StyledTypography = (props: TypographyProps & { answered?: boolean }) => (
  <Typography
    {...props}
    style={
      props.answered
        ? {
            opacity: 0.6,
          }
        : undefined
    }
  >
    {props.children}
  </Typography>
);

export default Quiz;
