import { useCallback, useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Theme,
  Select,
  SelectChangeEvent,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { IChallenge, IUserChallenge } from "../../types/challenge";
import Quiz from "../../components/Quiz";

const Challenges: React.FunctionComponent<{}> = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quizzesLoading, setQuizzesLoading] = useState<boolean>(false);
  const [challenge, setChallenge] = useState<IChallenge>();

  const [selectedChallenge, setSelectedChallenge] = useState<number>(0);
  const [userChellenges, setUserChallenges] = useState<IUserChallenge[]>([]);

  const loadChallengeMetadata = useCallback(() => {
    if (!selectedChallenge) {
      return;
    }

    axios
      .get<IChallenge>(`/Web/GetChallenge`, {
        params: {
          challengeId: selectedChallenge,
        },
      })
      .then((res) => {
        setChallenge((current) => ({
          ...current,
          ...res.data,
        }));
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [selectedChallenge]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IUserChallenge[]>("/Web/GetUserChallenges")
      .then((res) => {
        if (res.data.length > 0) {
          setSelectedChallenge(res.data[0].challengeId);
        }
        setUserChallenges(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const loadQuizzes = useCallback(() => {
    if (!selectedChallenge) {
      return;
    }
    setQuizzesLoading(true);
    axios
      .get<IChallenge>(`/Web/GetChallengeQuizzes`, {
        params: {
          challengeId: selectedChallenge,
        },
      })
      .then((res) => {
        setChallenge(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setQuizzesLoading(false);
      });
  }, [selectedChallenge]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadQuizzes();
  }, [loadQuizzes]);

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Challenges
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Select
              value={selectedChallenge.toString()}
              variant="filled"
              onChange={(event: SelectChangeEvent) => {
                setSelectedChallenge(parseInt(event.target.value));
              }}
              renderValue={(value) => {
                const test = userChellenges.find(
                  (test) => test.challengeId.toString() === value
                );

                if (!test) {
                  return "";
                }

                return `${test.contentName} in ${test.teamName}`;
              }}
            >
              {userChellenges.map((challenge) => (
                <MenuItem
                  key={challenge.challengeId}
                  value={challenge.challengeId}
                >
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography>
                        {challenge.contentName} in {challenge.teamName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {moment.utc(challenge.startDate).format("L")} -{" "}
                        {moment.utc(challenge.endDate).format("L")}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
      </Grid>

      {isLoading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : challenge ? (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography>
              <b>Duration:</b>
              {`📅 ${moment.utc(challenge.startDate).format("L")} - ${moment
                .utc(challenge.endDate)
                .format("L")} (${
                moment.utc(challenge.endDate).isAfter(moment())
                  ? "started"
                  : "finished"
              })`}
            </Typography>
            <Typography>
              <b>Goal:</b>
              {challenge.answersGivenCriteria}% answers given,{" "}
              {challenge.correctAnswersCriteria}% correct answers
            </Typography>
            <Typography>
              <b>Answers given:</b>{" "}
              {`${challenge.quizzesAnsweredPercent}% (${challenge.quizzesAnswered}/${challenge.quizzesSent})`}
            </Typography>
            <Typography>
              <b>Correct answers:</b>{" "}
              {`${challenge.quizzesCorrectPercent}% (${challenge.quizzesCorrect}/${challenge.quizzesAnswered})`}
            </Typography>
          </Stack>
          <div>
            <Grid container spacing={4}>
              {_.zip(
                ..._.chunk(challenge?.quizzes, smDown ? 1 : mdDown ? 2 : 3)
              ).map((column, index) => (
                <Grid
                  key={index}
                  container
                  item
                  xs
                  spacing={4}
                  direction="column"
                  role="column"
                >
                  {column.map((quiz) =>
                    quiz ? (
                      <Grid key={quiz.quizId} item>
                        <Quiz
                          quiz={quiz}
                          callback={() => {
                            loadChallengeMetadata();
                          }}
                        />
                      </Grid>
                    ) : null
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </Stack>
      ) : quizzesLoading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Typography variant="h5">
          You haven't been assigned any challenges yet. Challenges are managed
          by your workspace admins.
        </Typography>
      )}
    </Stack>
  );
};

export default Challenges;
