import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M32 12L37.6569 26.3431L52 32L37.6569 37.6569L32 52L26.3431 37.6569L12 32L26.3431 26.3431L32 12Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56 44L59.3941 52.6059L68 56L59.3941 59.3941L56 68L52.6059 59.3941L44 56L52.6059 52.6059L56 44Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
