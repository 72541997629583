import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";

import { Grid, Stack, Typography, Tab, Breadcrumbs, Link } from "@mui/material";

import Helper from "../../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../../constants/helpers";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import LessonsTab from "./LessonsTab";
import CourseTrendsTab from "./TrendsTab";
import Chevron from "../../../../../components/Icons/Chevron";

const CourseAnalytics: React.FunctionComponent<{}> = () => {
  const [tab, setTab] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Teams analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={
          <Chevron
            width={20}
            height={20}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
      >
        <Link
          component={RouterLink}
          to={"/analytics/teams"}
          underline="none"
          color="inherit"
        >
          Teams
        </Link>
        <Link
          component={RouterLink}
          to={"/analytics/teams/1"}
          underline="none"
          color="inherit"
        >
          Test
        </Link>

        <Typography variant="inherit">Effective communication</Typography>
      </Breadcrumbs>

      <TabContext value={tab}>
        <Stack direction="row">
          <TabList onChange={handleTabChange}>
            <Tab label="Trends" value="2" />
            <Tab label="Lessons" value="1" />
          </TabList>
        </Stack>
        <TabPanel value="1">
          <LessonsTab />
        </TabPanel>
        <TabPanel value="2">
          <CourseTrendsTab />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default CourseAnalytics;
