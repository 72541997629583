import { useEffect, useState } from "react";

import axios from "axios";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  IKnowledgeTrends,
  IKnowledgeTrendsFilters,
} from "../../../types/analytics";

import {
  Chart as ChartJS,
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";

import { TrendsAnalyticsHelper } from "../../../constants/helpers";
import Helper from "../../../components/Helper";
import CustomPaper from "../../../components/CustomPaper";
import GeneralAnalyticsCard from "../../../components/GenerAnalyticsCard";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
  ArcElement
);

const TrendsAnalytics: React.FunctionComponent<{}> = () => {
  const [filterData, setFilterData] = useState<IKnowledgeTrendsFilters>();
  const [selectedChannel, setSelectedChannel] = useState<number>(0);
  const [selectedContent, setSelectedContent] = useState<number>(0);
  const [data, setData] = useState<IKnowledgeTrends>();

  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get<IKnowledgeTrendsFilters>("/Alytics/GetKnowledgeTrendsFilter")
      .then((res) => {
        setFilterData(res.data);
        if (res.data.contentList[0]) {
          setSelectedContent(res.data.contentList[0].id);
        }
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsFiltersLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedContent !== 0) {
      setIsDataLoading(true);
      axios
        .get<IKnowledgeTrends>("/Alytics/GetKnowledgeTrends", {
          params: {
            questionCategoryId: selectedContent,
            channelId: selectedChannel,
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("error:", err);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [selectedChannel, selectedContent]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography component="h1" variant="h2">
              Trends in Team
            </Typography>
            <Select
              variant="filled"
              value={selectedChannel.toString()}
              onChange={(event: SelectChangeEvent) => {
                setSelectedChannel(parseInt(event.target.value));
              }}
              disableUnderline
            >
              {filterData?.channelList.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.teamName}
                </MenuItem>
              ))}
            </Select>
            <Helper title={<TrendsAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <Box>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Users completed onboarding"
              // primaryText={data?.numberOfParticipants.toString() || "0"}
              primaryText="92/100 (92%)"
              // loading={!data}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly active users"
              // primaryText={data?.usersCompletedChallenge.toString() || "0"}
              primaryText="76/92 (83%)"
              // loading={!data}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly lessons completed"
              // primaryText={data?.usersCompletedChallenge.toString() || "0"}
              primaryText="322/460 (70%)"
              // loading={!data}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Stack spacing={1}>
              <Typography variant="h3">
                Knowledge level in different topics
              </Typography>
              <CustomPaper sx={{ padding: 2 }}>
                <Line
                  style={{ maxHeight: 600 }}
                  options={{
                    responsive: true,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label(tooltipItem) {
                            return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                          },
                        },
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,

                        ticks: {
                          stepSize: 100,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    datasets: [
                      {
                        label: "Completed lessons",
                        data: [
                          180, 200, 110, 200, 190, 300, 200, 180, 200, 190, 180,
                          300,
                        ],
                        borderColor: `rgb(245, 212, 188)`,
                        tension: 0.4,
                        fill: true,
                        backgroundColor: `rgba(245, 212, 188, 0.15)`,
                      },
                      {
                        label: "Correct lessons",
                        data: [
                          100, 150, 100, 150, 100, 200, 150, 100, 150, 100, 100,
                          200,
                        ],
                        borderColor: `rgb(244, 121, 35)`,
                        tension: 0.4,
                        fill: true,
                        backgroundColor: `rgba(244, 121, 35, 0.3)`,
                      },
                    ],
                  }}
                />
              </CustomPaper>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={1}>
              <Typography variant="h3">
                Knowledge level in different topics
              </Typography>
              <CustomPaper sx={{ padding: 2 }}>
                <Pie
                  style={{ maxHeight: 600 }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      tooltip: {
                        callbacks: {
                          label(tooltipItem) {
                            return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [
                      "Cybersecurity",
                      "Employee",
                      "Effective communication",
                      "Employee well-being",
                      "Effective meetings",
                    ],
                    datasets: [
                      {
                        data: [39.11, 28.02, 23.13, 5.03, 4.71],
                        backgroundColor: [
                          "rgb(244, 121, 35)",
                          "rgb(250, 148, 76)",
                          "rgb(245, 212, 188)",
                          "rgb(250, 230, 210)",
                          "rgb(253, 243, 240)",
                        ],
                      },
                    ],
                  }}
                />
              </CustomPaper>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default TrendsAnalytics;

{
  /* ; */
}
