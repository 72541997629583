import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import { Link as RouterLink } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs,
  Grid,
  Link,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IEmployeesAnalyticsFilters } from "../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Helper from "../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../constants/helpers";
import GridToolbar from "../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";
import moment from "moment";
import Chevron from "../../../../components/Icons/Chevron";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  contentId?: number;
}

const TeamAnalytics: React.FunctionComponent<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      content: "Kiberdrošība",
      status: "active",
      trainingType: "Instant",
      startDate: moment().subtract(1, "day").toDate(),
      endDate: moment().add(10, "day").toDate(),
      lessonsSent: 7034,
      completedLessons: 6234,
      correctAnswers: 5993,
      lastDistributionDate: moment().toDate(),
    },
    {
      id: 2,
      content: "Employee wellbeing",
      status: "paused",
      trainingType: "regular",
      startDate: moment().subtract(1, "day").toDate(),
      lessonsSent: 1245,
      completedLessons: 1101,
      correctAnswers: 801,
      lastDistributionDate: moment().toDate(),
    },
    {
      id: 3,
      content: "Effective communication",
      status: "finished",
      trainingType: "mandatory",
      startDate: moment().subtract(1, "day").toDate(),
      endDate: moment().add(30, "day").toDate(),
      lessonsSent: 755,
      completedLessons: 667,
      correctAnswers: 349,
      lastDistributionDate: moment().toDate(),
    },
  ]);
  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Employee",
            value: "employeeName",
          },
          {
            label: "Team",
            value: "channelName",
          },
          {
            label: "Content",
            value: "contentName",
          },
          {
            label: "Sent lessons",
            value: "quizzesSent",
          },
          {
            label: "Completed lessons",
            value: "quizzesCompleted",
          },
          {
            label: "Completed lessons %",
            value: "quizzesCompletedPercent",
          },
          {
            label: "Correct lessons",
            value: "quizzesCorrect",
          },
          {
            label: "Correct lessons %",
            value: "quizzesCorrectPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeList,
      },
    ];

    xlsx(exportData, {
      fileName: "employees-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    // axios
    //   .get<IEmployeesAnalytics>("/Alytics/GetEmployeeAnalytics", {
    //     params: {
    //       ...queryOptions,
    //     },
    //   })
    //   .then((res) => {
    //     // setData(res.data);
    //   })
    //   .catch((err) => {
    //     console.log("error:", err);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, [queryOptions]);

  const columns: GridColDef[] = [
    {
      field: "content",
      headerName: "Content",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/analytics/teams/1/course/${params.row.id}`}
            component={RouterLink}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",

      sortable: false,
      minWidth: 100,
    },
    {
      field: "trainingType",
      headerName: "Training type",
      sortable: false,

      minWidth: 200,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "endDate",
      headerName: "Deadline",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "lessonsSent",
      headerName: "Lessons sent",
      sortable: false,

      minWidth: 200,
    },
    {
      field: "completedLessons",
      headerName: "Completed lessons",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "correctAnswers",
      headerName: "Correct answers",
      sortable: false,

      minWidth: 200,
    },
    {
      field: "lessonsSent",
      headerName: "Lessons sent",
      sortable: false,

      minWidth: 200,
    },
    {
      field: "lastDistributionDate",
      headerName: "Last distribution",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return moment(params.value).format("LT");
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Teams analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <Breadcrumbs
        separator={
          <Chevron
            width={20}
            height={20}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
      >
        <Link
          component={RouterLink}
          to={"/analytics/teams"}
          underline="none"
          color="inherit"
        >
          Teams
        </Link>
        <Typography variant="inherit">Test</Typography>
      </Breadcrumbs>

      <DataGrid
        ref={gridRef}
        disableColumnFilter
        getRowId={(row) => row.id}
        rows={data}
        rowCount={3}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={
                  Object.values(queryOptions).filter((item) => item).length
                }
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        columns={columns}
        // loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.userList : []}
            value={
              filterData
                ? filterData.userList.find(
                    (item) => item.id === queryOptions.userId
                  )
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <TextField {...params} label="Employee" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                userId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.channelId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find(
                    (item) => item.id === queryOptions.contentId
                  )
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                contentId: value ? value.id : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default TeamAnalytics;
