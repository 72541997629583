import { ICreateContentModalProps } from "../types/modal";

import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ICreateContent } from "../types/content";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useNavigate } from "react-router";

import createContentSchema from "../schemas/createContent";

import { track } from "@amplitude/analytics-browser";
import { useEffect } from "react";
import Actions from "../components/Modal/Actions";

const CreateContent: React.FunctionComponent<ICreateContentModalProps> = ({
  id,
  initialValues,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tour = useAppSelector((state) => state.tour);
  const languages = useAppSelector((state) => state.classifiers.languages);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    submitCount,
    errors,
  } = useFormik<ICreateContent>({
    initialValues: {
      name: initialValues?.name || "",
      languageId: 1,
      shuffleQuizzes: false,
      repeatQuizzes: false,
    },
    validationSchema: createContentSchema,
    onSubmit: (values, formikHelpers) => {
      axios
        .post<number>("/Content/CreateContent", {
          createContentSettings: values,
        })
        .then((res) => {
          navigate(`/courses/${res.data}`);
          handleClose();
        })
        .catch((err) => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  useEffect(() => {
    if (tour.index === 4 && tour.run === false) {
      handleSubmit();
    }
  }, [tour]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Create course</DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              value={values.languageId}
              label="Language"
              name="languageId"
              onChange={handleChange}
            >
              {languages.map((language) => (
                <MenuItem key={language.languageId} value={language.languageId}>
                  {language.languageName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name) && submitCount > 0}
            helperText={submitCount > 0 ? errors.name : null}
          />
          <FormControlLabel
            control={
              <Switch
                id="shuffleQuizzes"
                checked={values.shuffleQuizzes}
                onChange={handleChange}
              />
            }
            label="Shuffle content"
          />
          <FormControlLabel
            control={
              <Switch
                id="repeatQuizzes"
                checked={values.repeatQuizzes}
                onChange={handleChange}
              />
            }
            label="Repeat content"
          />
        </Stack>
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
          positive={{
            label: "Create",
            callback: handleSubmit,
            isLoading: isSubmitting,
            className: "create-course-modal-button",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateContent;
