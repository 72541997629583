export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 59.3827V16.0987C9.41773 15.8072 10.8507 15.5842 12.2934 15.4316C18.5544 14.7692 24.9012 15.4385 30.8945 17.3987C34.0819 18.4412 37.1351 19.836 40 21.5516V64.8356C37.1351 63.12 34.0819 61.7252 30.8945 60.6827C24.9012 58.7225 18.5544 58.0532 12.2934 58.7156C10.8507 58.8682 9.41773 59.0912 8 59.3827Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 21.5516C42.8649 19.836 45.9181 18.4412 49.1055 17.3987C55.0988 15.4385 61.4456 14.7692 67.7066 15.4316C69.1493 15.5842 70.5823 15.8072 72 16.0987V59.3827C70.5823 59.0912 69.1493 58.8682 67.7066 58.7156C61.4456 58.0532 55.0988 58.7225 49.1055 60.6827C45.9181 61.7252 42.8649 63.12 40 64.8356"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
