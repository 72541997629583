import { IApproveModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Dialog, DialogContent, Typography } from "@mui/material";
import Actions from "../components/Modal/Actions";

const ApproveModal: React.FunctionComponent<IApproveModalProps> = ({
  id,
  title,
  onApprove,
}) => {
  const dispatch = useAppDispatch();

  const handleApprove = () => {
    onApprove();
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogContent>
        {typeof title === "string" ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          title
        )}
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
          positive={{
            label: "Approve",
            callback: handleApprove,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ApproveModal;
