export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.175 69.825C31.6241 67.3759 34.9458 66 38.4094 66H41.5906C45.0542 66 48.3759 67.3759 50.825 69.825L52.2929 71.2929C52.5538 71.5538 52.369 72 52 72H28C27.631 72 27.4462 71.5538 27.7071 71.2929L29.175 69.825Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 52V66"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 8V26.4468C23 34.4316 26.5594 42.0008 32.7093 47.0936C34.9475 48.9472 37.4119 50.3825 40.0003 51.3995C42.5887 50.3825 45.053 48.9472 47.2913 47.0936C53.4411 42.0008 57.0005 34.4316 57.0005 26.4468V8.00047H23.1282C23.0855 8.00047 23.0427 8.00031 23 8Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.999 16H10.878C10.7127 16.4974 10.5692 17.0043 10.4486 17.5196C9.2206 22.7652 10.553 28.3486 13.9639 32.5188C16.986 36.2136 21.3993 38.5634 26.1181 38.9967"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.0009 16H69.1219C69.2872 16.4974 69.4307 17.0043 69.5513 17.5196C70.7793 22.7652 69.4469 28.3486 66.036 32.5188C63.0139 36.2136 58.6006 38.5634 53.8818 38.9967"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
