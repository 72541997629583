import { TRoute, RouteType } from "../types/route";

import Leaderboard from "../views/client/Leaderboard";
import Lessons from "../views/client/Lessons";
import Tests from "../views/client/Tests";
import Challenges from "../views/client/Challenges";

import ChartBar from "../components/Icons/ChartBar";
import Books from "../components/Icons/Books";
import NoteClipboard from "../components/Icons/NoteClipboard";
import Trophy from "../components/Icons/Trophy";

// INFO: path /docs is reserved route for documentation and handled as static content

export const routes: TRoute[] = [
  {
    type: RouteType.Menu,
    path: "/lessons",
    element: Lessons,
    name: "Lessons",
    icon: <Books />,
  },
  {
    type: RouteType.Menu,
    path: "/challenges",
    element: Challenges,
    name: "Challenges",
    icon: <Trophy />,
  },
  {
    type: RouteType.Menu,
    path: "/tests",
    element: Tests,
    name: "Tests",
    icon: <NoteClipboard />,
  },
  {
    type: RouteType.Menu,
    path: "/leaderboard",
    element: Leaderboard,
    name: "Leaderboard",
    icon: <ChartBar />,
  },
];
