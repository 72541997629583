import { GridToolbarColumnsButton, GridToolbarContainer } from "@mui/x-data-grid";
import Columns3 from "../Icons/Columns3";
import { Badge, Button } from "@mui/material";
import Filter from "../Icons/Filter";
import CSVExportButton from "./CSVExportButton";
import Upload from "../Icons/Upload";

interface IGridToolbarProps {
  toggleFilter: () => void;
  filterCount: number;
  onExportXLSX?: () => void;
}

const GridToolbar: React.FC<IGridToolbarProps> = ({ toggleFilter, filterCount, onExportXLSX }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        variant="contained"
        startIcon={<Columns3 width={25} height={25} color="#fff" />}
      />
      <Button
        variant="contained"
        startIcon={
          <Badge color="primary" badgeContent={filterCount}>
            <Filter width={25} height={25} color="#fff" />
          </Badge>
        }
        onClick={toggleFilter}
      >
        Filters
      </Button>
      {onExportXLSX && (
        <Button
          startIcon={<Upload width={25} height={25} color="#fff" />}
          variant="contained"
          onClick={onExportXLSX}
        >
          Export EXCEL
        </Button>
      )}
      {/* <CSVExportButton
        variant="contained"
        startIcon={<Upload width={25} height={25} color="#fff" />}
      /> */}
    </GridToolbarContainer>
  );
};

export default GridToolbar;
