import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  IconButton,
  Stack,
  Chip,
  Typography,
  Link,
  Button,
  Avatar,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { track } from "@amplitude/analytics-browser";
import Helper from "../../../components/Helper";
import { DistributeCoursesHelper } from "../../../constants/helpers";

import NoTeams from "./NoTeams";
import NoCourses from "./NoCourses";

import Plus from "../../../components/Icons/Plus";
import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";

import moment from "moment";

import Analytics from "../../../components/Icons/Analytics";
import Edit from "../../../components/Icons/Edit";
import {
  ICourseDistribution,
  IGetCourseDistributionsResponse,
} from "../../../types/distributeCourse";
import { COURSE_TRAINING_TYPE_LIST } from "../../../constants/courseTrainingType";

const DistributeCourses = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<IGetCourseDistributionsResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get<IGetCourseDistributionsResponse>(
        "/CourseDistributions/GetCourseDistributions",
        {
          params: { page: paginationModel.page + 1 },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page]);

  useEffect(() => {
    loadData();
    track("OpenedDistributeCourses");
  }, [loadData]);

  const columns: GridColDef<ICourseDistribution>[] = [
    {
      field: "questionCategoryName",
      headerName: "Course",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "channelName",
      headerName: "Team",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value || moment(params.value).year() < 2000) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value || moment(params.value).year() < 2000) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "days",
      headerName: "Receive lessons on",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        const avatars = moment.weekdays().map((day: string) => {
          // @ts-ignore
          const isScheduled = params.row[day.toLowerCase()];

          if (isScheduled) {
            return (
              <Avatar
                key={day}
                sx={{
                  bgcolor: "text.primary",
                  width: 24,
                  height: 24,
                  fontSize: 12,
                }}
              >
                {day[0]}
              </Avatar>
            );
          }
          return null;
        });

        if (avatars.filter((day) => day !== null).length === 0) {
          return "-";
        }

        return (
          <Stack direction="row" spacing={1}>
            {avatars}
          </Stack>
        );
      },
    },
    {
      field: "lessonTime",
      headerName: "Lesson Time",
      sortable: false,
      minWidth: 170,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return params.value;
      },
    },
    {
      field: "correctAnswersCriteria",
      headerName: "Correct Answers Goal",
      sortable: false,
      minWidth: 270,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return `${params.row.correctAnswersCriteria}%`;
      },
    },
    {
      field: "lessonCount",
      headerName: "Number of Lessons",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return params.value;
      },
    },
    {
      field: "courseTrainingType",
      headerName: "Training type",
      sortable: false,
      minWidth: 180,
      renderCell: (params) => {
        const courseTrainingType =
          COURSE_TRAINING_TYPE_LIST[params.row.courseTrainingType];

        return (
          <Chip
            label={courseTrainingType.name}
            style={{
              backgroundColor: courseTrainingType.color,
            }}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Link
              className="edit-team-button"
              component={RouterLink}
              to=""
              // to={`/teams/${params.row.channelId}/${params.row.language}`}
            >
              <IconButton>
                <Analytics />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.DistributeCourse,
                    initialValues: params.row,
                    callback: loadData,
                  })
                );
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Distribute Courses
            </Typography>
            <Helper title={<DistributeCoursesHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Plus width={25} height={25} color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.DistributeCourse,
                  callback: loadData,
                })
              );
            }}
          >
            Distribute a course
          </Button>
        </Grid>
      </Grid>

      {/* <NoTeams /> */}
      {/* <NoCourses /> */}

      <DataGrid
        getRowId={(row) => row.id}
        rows={data ? data.courseDistributions : []}
        rowCount={data ? data.numberOfEntries : 0}
        columns={columns}
        loading={isLoading}
        slotProps={{}}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
    </Stack>
  );
};

export default DistributeCourses;
