import { useCallback, useEffect, useState } from "react";

import { IUploadContentModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  LinearProgress,
  Box,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { track } from "@amplitude/analytics-browser";
import FileUpload from "../components/FileUpload";

import { enqueueSnackbar } from "notistack";

const UploadContent: React.FunctionComponent<IUploadContentModalProps> = ({
  id,
  questionCategoryId,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedValue, setSelectedValue] = useState<string>("quiz");

  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<{
    loaded: number;
    total: number;
  }>();

  const controller = new AbortController();

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);

    let formData = new FormData();
    formData.append("questionCategoryId", questionCategoryId.toString());
    formData.append("type", selectedValue);
    formData.append("file", files[0]);

    axios
      .post("/Content/GenerateQuizFromDoc", formData, {
        signal: controller.signal,
        onDownloadProgress: function (progressEvent) {
          console.log("download", progressEvent.loaded, progressEvent.total);
          setProgress({
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          });
          if (callback) {
            callback();
          }
        },
      })
      .then((res) => {
        enqueueSnackbar("Lessons generated successfully!", {
          variant: "success",
        });
        handleClose();

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [files, selectedValue, questionCategoryId]);

  const handleClose = () => {
    dispatch(closeModalById(id));
    controller.abort();
  };

  useEffect(() => {
    track("OpenedUploadContent");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Generate lessons from file</DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Select Lesson Type:</Typography>
            <RadioGroup
              value={selectedValue}
              onChange={(event) => {
                setSelectedValue(event.target.value);
              }}
            >
              <FormControlLabel
                value="quiz"
                control={<Radio color="primary" />}
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    Quiz - Test employees' knowledge with quiz-style questions
                  </Typography>
                }
              />
              <FormControlLabel
                value={"educational"}
                control={<Radio color="primary" />}
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    Educational - Provide educational content followed by
                    questions to reinforce learning
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <FileUpload
            maxFiles={1}
            values={files}
            onFileDrop={(value) => {
              setFiles(value);
            }}
            onFileDelete={(file) => {
              setFiles((prev) => prev.filter((x) => x !== file));
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          disabled={files.length === 0}
          loading={isSubmitting}
          onClick={() => onSubmit()}
        >
          Generate
        </LoadingButton>
      </DialogActions>

      {isSubmitting && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <LinearProgress
            style={{
              height: 24,
            }}
            variant={progress ? "determinate" : "indeterminate"}
            value={progress ? (progress.loaded / progress.total) * 100 : 0}
          />
          <Typography
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            {progress
              ? `${Math.round((progress.loaded / progress.total) * 100)}%`
              : "0%"}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default UploadContent;
