import { LIST as DIFFICULTIES_LIST } from "../../../../../constants/dificulties";

import { Avatar, Box, Chip, Grid, Stack, Typography } from "@mui/material";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import CustomPaper from "../../../../../components/CustomPaper";

import Calendar from "../../../../../components/Icons/Calendar";
import Clock from "../../../../../components/Icons/Clock";
import Hash from "../../../../../components/Icons/Hash";
import Target from "../../../../../components/Icons/Target";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const CourseTrendsTab: React.FunctionComponent<{}> = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Stack spacing={4}>
          <Box>
            <Typography variant="h3">Training details</Typography>
            <CustomPaper sx={{ padding: 2 }}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip label="Mandatory" />
                  <Typography>trainign</Typography>
                </Stack>
                <Stack direction="row">
                  <Chip label="Finished" />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Calendar />
                  <Typography>01/01/2025-25/06/2025</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Receive lessons on</Typography>
                  <Avatar
                    sx={{
                      bgcolor: "text.primary",
                      width: 24,
                      height: 24,
                      fontSize: 12,
                    }}
                  >
                    M
                  </Avatar>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Clock />
                  <Typography>14:00 (GMT+3)</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Hash />
                  <Typography>25 lessons</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Target />
                  <Typography>20/25 correct answer goal</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip label="English" />
                  <Typography>language</Typography>
                </Stack>
              </Stack>
            </CustomPaper>
          </Box>
          <Box>
            <Typography variant="h3">Correct answer average</Typography>
            <CustomPaper sx={{ padding: 2 }}>O</CustomPaper>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h3">
          Knowledge level in different topics
        </Typography>
        <CustomPaper sx={{ padding: 2 }}>
          <Radar
            style={{ maxHeight: 600 }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "left",
                },
                tooltip: {
                  callbacks: {
                    label(tooltipItem) {
                      return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                    },
                  },
                },
              },
            }}
            data={{
              labels: [
                "Sistēmu arhitektūra",
                "Datu nesēji",
                "Izspiedējvīrusi",
                "Sociālā inženierija",
                "Ļaunatūra",
                "Termini un definīcijas",
                "Datu aizsardzība",
              ],
              datasets: DIFFICULTIES_LIST.map((difficulty, index) => {
                const data = [
                  [60, 60, 40, 20, 10, 10, 60],
                  [100, 10, 0, 20, 50, 10, 60],
                  [20, 20, 10, 50, 20, 60, 60],
                ];

                return {
                  label: difficulty.name,
                  data: data[index],
                  borderColor: `rgb(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]})`,
                  backgroundColor: `rgba(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]}, 0.15)`,
                };
              }),
            }}
          />
        </CustomPaper>
      </Grid>
    </Grid>
  );
};

export default CourseTrendsTab;
