import React, { useEffect, useState } from "react";

// utils
import { routes } from "./config/routes";
import { routes as clientRoutes } from "./config/clientRoutes";

import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { setFluid } from "./features/settings";

import { styled, ThemeProvider } from "@mui/material/styles";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Grid,
  Container,
  IconButton,
  Divider,
  List,
  Toolbar,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  MenuItem,
  Avatar,
  Menu,
  CircularProgress,
  useMediaQuery,
  Collapse,
  Tooltip,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

import PersonIcon from "@mui/icons-material/Person";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import logo from "./assets/img/logo.png";

import ModalManager from "./providers/Modal";
import { IMenuRoute, RouteType, TRoute } from "./types/route";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { tokenRequest } from "./config/auth";
import axios from "axios";

import { setUserId, track } from "@amplitude/analytics-browser";

import DataLoader from "./providers/DataLoader";
import ROLES from "./constants/roles";
import Auth from "./views/Auth";

import baseTheme from "./themes/base";
import MenuItemContainer from "./components/MenuItemContainer";
import Monitor from "./components/Icons/Monitor";
import Chevron from "./components/Icons/Chevron";
import Chat from "./components/Chat";

import configParams from "./config/params";
import TourManager from "./providers/Tour";

const drawerWidth: number = 240;

const Layout: React.FunctionComponent<{}> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    switch (inProgress) {
      case InteractionStatus.None:
        if (accounts.length > 0) {
          setUserId(accounts[0].localAccountId);

          axios.interceptors.request.use(
            async (config) => {
              const response = await instance.acquireTokenSilent({
                ...tokenRequest,
                account: accounts[0],
              });

              return {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${response.accessToken}`,
                },
              };
            },
            (error) => {
              return Promise.reject(error);
            }
          );

          setInitialized(true);

          if (configParams.webDomains.includes(window.location.host)) {
            track("AuthenticateUserWeb");
          } else {
            track("AuthenticateUserHub");
          }
        } else {
          setInitialized(true);
        }
        break;
      default:
        break;
    }
  }, [inProgress, accounts, instance]);

  return (
    <ThemeProvider theme={baseTheme}>
      <CssBaseline />

      {inProgress === InteractionStatus.Login || !initialized ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          {isAuthenticated ? (
            <DataLoader>
              <RenderApplication>{children}</RenderApplication>
            </DataLoader>
          ) : (
            <Auth />
          )}
        </>
      )}
    </ThemeProvider>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "visible",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      // overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(12),
      },
    }),
  },
}));

const RenderApplication: React.FunctionComponent<{}> = ({ children }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const [variant, setVariant] = useState<"permanent" | "temporary">(
    "permanent"
  );

  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.me);
  const settings = useAppSelector((state) => state.settings);

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (mdDown) {
      setOpen(false);
      setVariant("temporary");
    } else {
      setOpen(true);
      setVariant("permanent");
    }
  }, [mdDown]);

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Grid container justifyContent="space-between">
            {mdDown && (
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    ...(open && { visibility: "hidden" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <Link underline="none" to="/" component={RouterLink}>
                <img src={logo} alt="logo" style={{ width: 100 }} />
              </Link>
            </Grid>
            <Grid item>
              <ProfileDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          variant={variant}
          open={open}
          onClose={toggleDrawer}
          style={{
            maxHeight: mdDown ? "100vh" : "calc(100vh - 64px)",
          }}
        >
          {mdDown && <Toolbar />}
          <IconButton
            size="small"
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              right: -20,
              top: 95,
            }}
            onClick={() => {
              setOpen((prevState) => !prevState);
            }}
          >
            <Chevron
              style={{
                transform: open ? "rotate(270deg)" : "rotate(90deg)",
              }}
            />
          </IconButton>
          <MenuItemContainer>
            {(configParams.webDomains.includes(window.location.host)
              ? clientRoutes
              : routes
            )
              .filter((route) => route.type !== RouteType.Link)
              .map((route, index) => {
                if (!me) {
                  return null;
                }

                return (
                  <CustomMenuItem
                    key={index}
                    route={route}
                    roleId={me.roleId}
                    sidebarOpen={open}
                    onClick={() => {
                      if (mdDown) {
                        toggleDrawer();
                      }
                    }}
                  />
                );
              })}
          </MenuItemContainer>
          <Grid
            container
            flexDirection="column"
            flex={1}
            justifyContent="flex-end"
            style={{
              maxWidth: "100%",
            }}
          >
            {xlUp && open && (
              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.fluid}
                        onChange={() => {
                          dispatch(setFluid(!settings.fluid));
                        }}
                      />
                    }
                    label={<Monitor />}
                  />
                </Stack>
              </Grid>
            )}
            {!configParams.webDomains.includes(window.location.host) && (
              <Grid
                item
                style={{
                  maxWidth: "100%",
                }}
                sx={{
                  marginTop: -2,
                }}
              >
                <MenuItemContainer>
                  {routes
                    .filter((route) => route.type === RouteType.Link)
                    .map((route, index) => {
                      if (!me) {
                        return null;
                      }

                      return (
                        <CustomMenuItem
                          key={index}
                          route={route}
                          roleId={me.roleId}
                          sidebarOpen={open}
                          onClick={() => {
                            if (mdDown) {
                              toggleDrawer();
                            }
                          }}
                        />
                      );
                    })}
                </MenuItemContainer>
              </Grid>
            )}
          </Grid>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#FFF6F0",
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            maxHeight: "calc(100vh - 64px)",
            overflowY: "auto",
            flexGrow: 1,
            pb: 3,
          }}
        >
          <Container
            maxWidth={settings.fluid ? false : "lg"}
            sx={{ mt: 4, flex: 1, display: "flex", flexDirection: "column" }}
          >
            {children}
            <Chat />
          </Container>
        </Box>
      </Box>
      <ModalManager />
      <TourManager />
    </>
  );
};

const CustomMenuItem: React.FunctionComponent<{
  route: TRoute;
  roleId: ROLES;
  sidebarOpen: boolean;
  onClick: () => void;
  nested?: boolean;
}> = ({ route, roleId, onClick, sidebarOpen, nested }) => {
  const [open, setOpen] = useState(false);

  if ("allowedRoles" in route && route.allowedRoles) {
    if (!route.allowedRoles.includes(roleId)) {
      return null;
    }
  }

  switch (route.type) {
    case RouteType.Basic:
      return null;
    case RouteType.Group:
      return (
        <List disablePadding>
          <Tooltip
            title={route.name}
            placement="right"
            arrow
            disableHoverListener={sidebarOpen}
          >
            <ListItemButton onClick={() => setOpen((e) => !e)}>
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText
                primary={route.name}
                sx={{
                  opacity: sidebarOpen ? 1 : 0,
                }}
              />
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{ pl: sidebarOpen ? 2 : 0 }}
            >
              {route.routes.map((e) => (
                <CustomMenuItem
                  key={e.path}
                  route={e}
                  roleId={roleId}
                  onClick={onClick}
                  sidebarOpen={sidebarOpen}
                  nested
                />
              ))}
            </List>
          </Collapse>
        </List>
      );
    case RouteType.Link:
      return (
        <List disablePadding>
          <Tooltip
            title={route.name}
            placement="right"
            arrow
            disableHoverListener={sidebarOpen}
          >
            <Link
              href={route.link}
              underline="none"
              color="inherit"
              onClick={() => onClick()}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText
                    primary={route.name}
                    sx={{
                      opacity: sidebarOpen ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        </List>
      );
    default:
      return (
        <List disablePadding>
          <Tooltip
            title={route.name}
            placement="right"
            arrow
            disableHoverListener={sidebarOpen}
          >
            <Link
              component={RouterLink}
              to={route.path}
              underline="none"
              color="inherit"
              onClick={() => onClick()}
              className={route.className}
            >
              <MenuListItem
                route={route}
                sidebarOpen={sidebarOpen}
                nested={nested}
              />
            </Link>
          </Tooltip>
        </List>
      );
  }
};

const MenuListItem: React.FunctionComponent<{
  route: IMenuRoute;
  sidebarOpen: boolean;
  nested?: boolean;
}> = ({ route, sidebarOpen, nested }) => {
  let resolved = useResolvedPath(route.path);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={match ? true : false}
        sx={{
          ...(nested && {
            ml: -2,
            pl: sidebarOpen ? 2 : 0,
          }),
        }}
      >
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText
          primary={route.name}
          sx={{
            opacity: sidebarOpen ? 1 : 0,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

function ProfileDropdown() {
  const { instance, accounts } = useMsal();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <Link component={RouterLink} to="/profile" underline="none" color="inherit"> */}
        <MenuItem disabled>
          <Avatar alt={accounts[0]?.name} />
          {accounts[0]?.name}
        </MenuItem>
        {/* </Link> */}
        <Divider />
        <MenuItem
          onClick={() => {
            if (configParams.webDomains.includes(window.location.host)) {
              track("LogoutUserWeb");
            } else {
              track("LogoutUserHub");
            }

            instance.logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}

export default Layout;
