import { useMsal } from "@azure/msal-react";

import { Container, Button, Stack } from "@mui/material";
import Copyright from "../components/Copyright";
import { loginHubRequest, loginWebRequest } from "../config/auth";

import logo from "../assets/img/logo.png";
import background from "../assets/img/auth_bg.png";

import TeamsIcon from "../components/Icons/Teams";
import MicrosoftIcon from "../components/Icons/Microsoft";

import configParams from "../config/params";

const Auth: React.FC = () => {
  const { instance } = useMsal();

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Stack flex={1} spacing={2} alignItems="center" justifyContent="center">
        <img src={logo} alt="logo" style={{ maxWidth: 300 }} />

        <Button
          variant="contained"
          color="primary"
          startIcon={
            configParams.webDomains.includes(window.location.host) ? (
              <MicrosoftIcon />
            ) : (
              <TeamsIcon />
            )
          }
          onClick={() => {
            instance.acquireTokenRedirect(
              configParams.webDomains.includes(window.location.host)
                ? loginWebRequest
                : loginHubRequest
            );
          }}
        >
          {configParams.webDomains.includes(window.location.host)
            ? "Sign in with Microsoft"
            : "Sign in with Teams"}
        </Button>
      </Stack>
      <Copyright />
    </Container>
  );
};

export default Auth;
