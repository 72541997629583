import { useState } from "react";
import axios from "axios";
import _ from "lodash";

import {
  Grid,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useAppSelector } from "../../../hooks/redux";
import { IContentEdit, ICreateContent } from "../../../types/content";

import createContentSchema from "../../../schemas/createContent";
import CustomPaper from "../../../components/CustomPaper";
import Save from "../../../components/Icons/Save";

const ContentDetails: React.FC<{
  content: IContentEdit;
  callback: () => void;
}> = ({ content, callback }) => {
  const languages = useAppSelector((state) => state.classifiers.languages);

  const [initialValues, setInitialValues] = useState<ICreateContent>({
    name: content.name,
    languageId: content.languageId,
    repeatQuizzes: content.repeatQuizzes,
    shuffleQuizzes: content.shuffleQuizzes,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    submitCount,
    errors,
    dirty,
  } = useFormik<ICreateContent>({
    initialValues: initialValues,
    validationSchema: createContentSchema,
    enableReinitialize: true,
    onSubmit: (values, formikHelpers) => {
      axios
        .post<number>("/Content/UpdateContent", {
          questionCategoryId: content.questionCategoryId,
          updateQuestionCategoriesSettings: values,
        })
        .then((res) => {
          setInitialValues(values);
          formikHelpers.setSubmitting(false);
          callback();
        })
        .catch((err) => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  return (
    <CustomPaper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={values.name}
              inputProps={{
                maxLength: 50,
              }}
              fullWidth
              onChange={handleChange}
              error={Boolean(errors.name) && submitCount > 0}
              helperText={submitCount > 0 ? errors.name : null}
              disabled={!content.canEdit}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth disabled={!content.canEdit}>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                value={values.languageId}
                label="Language"
                name="languageId"
                onChange={handleChange}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.languageId}
                    value={language.languageId}
                  >
                    {language.languageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            {content.questionCategoryEnumId !== 1 ? (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      id="shuffleQuizzes"
                      disabled={!content.canEdit}
                      checked={values.shuffleQuizzes}
                      onChange={handleChange}
                    />
                  }
                  label="Shuffle content"
                />
                <FormControlLabel
                  control={
                    <Switch
                      id="repeatQuizzes"
                      disabled={!content.canEdit}
                      checked={values.repeatQuizzes}
                      onChange={handleChange}
                    />
                  }
                  label="Repeat content"
                />
              </>
            ) : null}
          </Stack>
          <LoadingButton
            loading={isSubmitting}
            onClick={() => handleSubmit()}
            disabled={!content.canEdit || !dirty}
            variant="contained"
            startIcon={<Save width={25} height={25} color="#fff" />}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </CustomPaper>
  );
};

export default ContentDetails;
