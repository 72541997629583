import { useCallback, useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Theme,
  Select,
  SelectChangeEvent,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { IQuiz } from "../../types/quiz";
import _ from "lodash";
import Quiz from "../../components/Quiz";
import { IUserTeam } from "../../types/team";

const Lessons: React.FunctionComponent<{}> = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quizzes, setQuizzes] = useState<IQuiz[]>([]);

  const [selectedChannel, setSelectedChannel] = useState<number>(0);
  const [channels, setChannels] = useState<IUserTeam[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<{
        quizzes: IQuiz[];
      }>("/Web/GetQuizzes", {
        params: {
          channelId: selectedChannel ? selectedChannel : undefined,
        },
      })
      .then((res) => {
        setQuizzes(res.data.quizzes);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedChannel]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    axios
      .get<{
        channels: IUserTeam[];
      }>("/Web/GetUserChannels")
      .then((res) => {
        setChannels(res.data.channels);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Lessons
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Select
              value={selectedChannel.toString()}
              variant="filled"
              onChange={(event: SelectChangeEvent) => {
                setSelectedChannel(parseInt(event.target.value));
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {channels.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.teamName}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
      </Grid>

      {isLoading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <div>
          <Grid container spacing={4}>
            {_.zip(..._.chunk(quizzes, smDown ? 1 : mdDown ? 2 : 3)).map((column, index) => (
              <Grid key={index} container item xs spacing={4} direction="column" role="column">
                {column.map((quiz) =>
                  quiz ? (
                    <Grid key={quiz.quizId} item>
                      <Quiz quiz={quiz} />
                    </Grid>
                  ) : null
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Stack>
  );
};

export default Lessons;
