import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import {
  Autocomplete,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
  Box,
  Chip,
  Avatar,
} from "@mui/material";
import { IEmployeesAnalyticsFilters } from "../../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import GridToolbar from "../../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";

import CustomPaper from "../../../../../components/CustomPaper";
import Calendar from "../../../../../components/Icons/Calendar";
import Clock from "../../../../../components/Icons/Clock";
import Hash from "../../../../../components/Icons/Hash";
import Target from "../../../../../components/Icons/Target";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  contentId?: number;
}

const CourseLessonsTab: React.FunctionComponent<{}> = () => {
  const [data, setData] = useState([
    {
      id: 1,
      employee: "John Doe",
      question: "Kas ir IoT?",
      topic: "Termini un definīcijas",
      difficulty: "Easy",
      timeSent: "13:30 05/05/2023",
      timeAnswered: "13:48 05/05/2023",
      answerGiven:
        'Ierīču kategorija, ko apzīmē ar "Internet of Things" un kas ietver ļoti plašu specializētu iekārtu kopu.',
      correct: true,
    },
  ]);
  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Employee",
            value: "employeeName",
          },
          {
            label: "Team",
            value: "channelName",
          },
          {
            label: "Content",
            value: "contentName",
          },
          {
            label: "Sent lessons",
            value: "quizzesSent",
          },
          {
            label: "Completed lessons",
            value: "quizzesCompleted",
          },
          {
            label: "Completed lessons %",
            value: "quizzesCompletedPercent",
          },
          {
            label: "Correct lessons",
            value: "quizzesCorrect",
          },
          {
            label: "Correct lessons %",
            value: "quizzesCorrectPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeList,
      },
    ];

    xlsx(exportData, {
      fileName: "employees-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    // axios
    //   .get<IEmployeesAnalytics>("/Alytics/GetEmployeeAnalytics", {
    //     params: {
    //       ...queryOptions,
    //     },
    //   })
    //   .then((res) => {
    //     // setData(res.data);
    //   })
    //   .catch((err) => {
    //     console.log("error:", err);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, [queryOptions]);

  const columns: GridColDef[] = [
    {
      field: "employee",
      headerName: "Employee",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "question",
      headerName: "Question",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "topic",
      headerName: "Topic",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      sortable: false,
      minWidth: 140,
    },
    {
      field: "timeSent",
      headerName: "Time sent",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "timeAnswered",
      headerName: "Time answered",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "answerGiven",
      headerName: "Answer given",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "correct",
      headerName: "Correct",
      sortable: false,
      minWidth: 140,
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="h3">Training details</Typography>
              <CustomPaper sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip label="Mandatory" />
                    <Typography>trainign</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Chip label="Finished" />
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Calendar />
                    <Typography>01/01/2025-25/06/2025</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Receive lessons on</Typography>
                    <Avatar
                      sx={{
                        bgcolor: "text.primary",
                        width: 24,
                        height: 24,
                        fontSize: 12,
                      }}
                    >
                      M
                    </Avatar>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Clock />
                    <Typography>14:00 (GMT+3)</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Hash />
                    <Typography>25 lessons</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Target />
                    <Typography>20/25 correct answer goal</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip label="English" />
                    <Typography>language</Typography>
                  </Stack>
                </Stack>
              </CustomPaper>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">Correct answer average</Typography>
              <CustomPaper sx={{ padding: 2 }}>O</CustomPaper>
            </Grid>
          </Grid>
        </Box>

        <Stack>
          <Typography variant="h3">Lessons</Typography>
          <DataGrid
            ref={gridRef}
            disableColumnFilter
            getRowId={(row) => row.id}
            rows={data}
            rowCount={3}
            components={{
              Toolbar: () => {
                return (
                  <GridToolbar
                    filterCount={
                      Object.values(queryOptions).filter((item) => item).length
                    }
                    toggleFilter={() => setIsFilterOpen(true)}
                    onExportXLSX={handleXLSXExport}
                  />
                );
              },
            }}
            columns={columns}
            // loading={isLoading}
            autoHeight
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[100]}
          />
        </Stack>
      </Stack>

      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.userList : []}
            value={
              filterData
                ? filterData.userList.find(
                    (item) => item.id === queryOptions.userId
                  )
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <TextField {...params} label="Employee" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                userId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.channelId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find(
                    (item) => item.id === queryOptions.contentId
                  )
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                contentId: value ? value.id : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default CourseLessonsTab;
